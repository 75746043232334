import React from 'react'
import { useEffect } from 'react'

export default function PolicyAndTermTh(props) {
  return (
    <div className="fade-in" lang={`${props.lang}`}>
      <section id="term"></section>
      <div className="h-24 max:h-36 2xl:h-32 xl:h-28 lg:h-20 md:h-36 sm:h-28"></div>
      <div className="container-mobile mx-auto">
        <section id="term"></section>
        <div className="container-mobile mx-auto">
          <div
            className="
                        text-body-header font-bold
                        text-size-20 max:text-size-45 2xl:text-size-40 xl:text-size-35 lg:text-size-30 md:text-size-30 sm:text-size-25
                    "
          >
            ข้อตกลงการใช้บริการ
          </div>
          <br />
          อัพเดทล่าสุด 17/01/2022
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อตกลงการใช้บริการ www.aappoint.me
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            ข้อกำหนดและเงื่อนไขทั้งหมดในเอกสารฉบับนี้ใช้บังคับสำหรับการที่ท่านเข้าใช้เว็บไซต์
            (หรือแอปพลิเคชันทางโทรศัพท์มือถือ) ของ บริษัท แอพพ้อยท์เม้นท์
            เอนี่แวร์ จำกัด (“บริษัท”) ซึ่งรวมถึงเว็บไซต์ www.aappoint.me
            <br />
            <br />
            การใช้งานเว็บไซต์ (หรือแอปพลิเคชันทางโทรศัพท์มือถือ)
            ถือว่าท่านรับทราบและยอมรับ เงื่อนไขและข้อกำหนดต่างๆ
            เหล่านี้ทั้งหมดแล้ว ดังนั้น
            หากท่านไม่ตกลงที่จะผูกพันตามข้อกำหนดข้อใดข้อหนึ่ง หรือทั้งหมด
            กรุณาอย่าเข้าใช้เว็บไซต์ (หรือแอพพลิเคชั่นทางโทรศัพท์มือถือ)
            <br />
            <br />
            บริษัทขอสงวนสิทธิในการแก้ไขหรือเปลี่ยนแปลงข้อกำหนดการใช้ นโยบาย
            หรือคู่มือใดๆ
            ของเว็บไซต์ตลอดเวลาตามดุลยพินิจของบริษัทแต่เพียงผู้เดียว
            ซึ่งการแก้ไขหรือเปลี่ยนแปลงดังกล่าวจะมีผลทันทีเมื่อมีการประกาศฉบับแก้ไขลงในเว็บไซต์
            และท่านตกลงสละสิทธิใด ๆ
            ที่อาจมีในการรับทราบคำบอกกล่าวเกี่ยวกับการแก้ไขเปลี่ยนแปลงดังกล่าว
            ดังนั้น หากท่านไม่เห็นด้วยกับการแก้ไขเปลี่ยนแปลงดังกล่าว
            กรุณาหยุดการเข้าใช้เว็บไซต์
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <br />
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            การลงทะเบียนและบัญชีผู้ใช้งาน
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            บริษัทขอสงวนสิทธิให้เฉพาะผู้ใช้งานที่ลงทะเบียนเพื่อสร้างบัญชีกับเว็บไซต์ของบริษัทที่จะสามารถสั่งซื้อสินค้า
            ใช้บริการ หรือร่วมทำกิจกรรมต่าง ๆ ที่จัดให้มีในเว็บไซต์ เป็นต้น
            เมื่อท่านตกลงสร้างบัญชี ให้ถือว่าท่านตกลงดังต่อไปนี้
            <br />
            <br />
            <ul className="list-disc">
              <li>
                ผู้ใช้งานหนึ่งท่านสามารถลงทะเบียนสร้างบัญชีได้เพียงหนึ่งบัญชีเท่านั้น
              </li>
              <li>
                ท่านตกลงให้ข้อมูลรายละเอียดที่ถูกต้อง ตรงต่อความเป็นจริง
                และเป็นปัจจุบัน
              </li>
              <li>ท่านจะปรับปรุงข้อมูลของท่านให้เป็นปัจจุบันอย่างสม่ำเสมอ</li>
              <li>
                ท่านจะรักษาความปลอดภัยของบัญชีของท่านโดยเก็บรักษาชื่อบัญชีและรหัสผ่านไว้เป็นความลับของท่านแต่เพียงผู้เดียว
                และจำกัดไม่ให้บุคคลภายนอกเข้าถึงบัญชีและคอมพิวเตอร์ของคุณ
              </li>
              <li>
                ท่านจะแจ้งให้เว็บไซต์และบริษัททราบทันทีที่ท่านสงสัยว่ามีการละเมิดความปลอดภัยเกี่ยวกับบัญชีของท่าน
              </li>
              <li>
                ท่านตกลงรับผิดชอบต่อการกระทำใด ๆ
                ที่เกิดขึ้นผ่านการใช้บัญชีของท่าน
                และยอมรับความเสี่ยงทุกกรณีที่อาจเกิดขึ้นเกี่ยวกับการเข้าใช้บัญชีของท่านโดยไม่ได้รับอนุญาต
                <ul className="list-disc">
                  <li>
                    สำหรับสมาชิกออนไลน์ที่มีอายุต่ำกว่า 20 ปี การทำธุรกรรมใด ๆ
                    ที่เกี่ยวข้องกับการเงิน เช่น สั่งซื้อสินค้าออนไลน์ ใช้บริการ
                    หรือร่วมทำกิจกรรมต่าง ๆ ที่จัดให้มีในเว็บไซต์
                    ท่านควรตรวจสอบเงื่อนไขการใช้งานภายใต้ความดูแลของผู้ปกครองตามกฎหมาย
                    และดำเนินการให้แน่ใจว่าผู้ปกครองของท่านเข้าใจเงื่อนไขและข้อกำหนดการใช้งานเว็บไซต์แล้ว
                  </li>
                  <li>
                    บริษัทสงวนสิทธิโดยมีดุลยพินิจแต่เพียงผู้เดียวที่จะเพิกถอนการเป็นสมาชิกของท่านได้ทันทีโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                    หากบริษัทพบการกระทำดังต่อไปนี้
                    <ul className="list-square">
                      <li>
                        ท่านให้ข้อมูลที่เป็นเท็จในการลงทะเบียน
                        รวมถึงการไม่ปรับปรุงข้อมูลให้เป็นข้อมูลปัจจุบัน
                      </li>
                      <li>พบการเข้าใช้งานบัญชีของท่านโดยบุคคลอื่น</li>
                      <li>
                        การกระทำใด ๆ ผ่านบัญชีของท่าน
                        ซึ่งเป็นการปฏิบัติผิดเงื่อนไขการใช้งานภายใต้ข้อตกลงการใช้บริการ
                        และเงื่อนไขอื่นซึ่งบริษัทกำหนด
                      </li>
                      <li>
                        การกระทำใด ๆ ผ่านบัญชีของท่าน โดยไม่ถูกต้องตามกฎหมาย
                        หรือศีลธรรมอันดีของประชาชน
                        หรือมีการใช้งานโดยมีเจตนาทุจริต
                      </li>
                    </ul>
                    <li>
                      บริษัทจัดเก็บ รวมรวม ใช้
                      และเปิดเผยข้อมูลของท่านเกี่ยวกับการลงทะเบียนและการใช้งานเว็บไซต์ตามนโยบายว่าด้วยข้อมูลส่วนบุคคลซึ่ง
                      รวมถึงแต่ไม่จำกัดเฉพาะ
                      การเปิดเผยข้อมูลของท่านเฉพาะที่เกี่ยวข้องให้แก่ผู้จัด
                      (ตามที่มีนิยามด้านล่าง)
                      ท่านสามารถพิจารณารายละเอียดได้ในเอกสารดังกล่าว
                    </li>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อจำกัดการใช้งาน
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            ในการใช้งานเว็บไซต์นี้ ท่านจะดำเนินการภายใต้กฎหมาย ระเบียบ
            และข้อบังคับที่เกี่ยวข้อง และตกลงดังต่อไปนี้
            <br />
            <ul className="list-disc">
              <li>ห้ามเผยแพร่ข้อความอันส่งผลเสียหายแก่ผู้อื่น</li>
              <li>
                ห้ามเผยแพร่ข้อความที่ ผิดกฎหมาย ข่มขู่คุกคาม กล่าวร้าย
                หมิ่นประมาท ละเมิดต่อสิทธิ หยาบคาย ลามกอนาจาร ดูหมิ่น ทำลาย
                เหยียดหยาม ต่อผู้อื่น
              </li>
              <li>ห้ามปลุกระดมให้เกิดการจลาจล</li>
              <li>ห้ามใช้ในเชิงพาณิชย์โดยไม่ได้รับอนุญาตจากบริษัท</li>
              <li>
                ห้ามจำกัดมิให้บุคคลอื่นใช้งานเว็บไซต์โดยไม่ได้รับอนุญาตจากบริษัท
              </li>
              <li>
                ห้ามเผยแพร่ลิ้งก์ไปยังเว็บไซต์ หรือเนื้อหาอื่นใด
                ซึ่งมีลักษณะที่ขัดต่อกฎหมาย หรือไม่เหมาะสม
              </li>
              <li>
                ห้ามเผยแพร่สแปม การติดต่อทางการตลาดโดยตรง
                หรือการโฆษณาโดยไม่ได้รับอนุญาต สื่อสนับสนุนการขาย หรือการชักชวน
                การค้าขายในรูปแบบอื่น ๆ
              </li>
              <li>
                ห้ามเผยแพร่ไวรัส สปายแวร์ โทรจัน โปรแกรมซ่อน
                หรือไฟล์อื่นใดที่เป็นอันตราย ก่อให้เกิดความเสียหายต่อเว็บไซต์
                หรือผู้ใช้งานเว็บไซต์
              </li>
            </ul>
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อกำหนดเกี่ยวกับสินค้าหรือบริการ
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            ท่านรับทราบและยอมรับว่า เว็บไซต์ www.aappoint.me
            มีวัตถุประสงค์ที่จะให้ท่านสามารถเข้าถึงสินค้าหรือบริการ ซึ่งรวมถึง
            การจองหรือการนัดหมาย ได้อย่างสะดวก รวดเร็วและมีประสิทธิภาพ
            รวมถึงสนับสนุนกิจกรรมพาณิชย์อิเล็กทรอนิกส์ของท่านเท่านั้น
            บริษัทมิได้เป็นผู้จัดงาน ผู้ขายสินค้าหรือบริการ หรือผู้ดำเนินการใด ๆ
            เกี่ยวกับกิจกรรม บริการ หรือสินค้าดังกล่าวแต่ประการใด ดังนั้น
            เมื่อท่านสั่งซื้อสินค้าผ่านเว็บไซต์นี้
            ถือว่าท่านได้ให้ความตกลงเกี่ยวกับการซื้อสินค้า หรือ
            บริการดังต่อไปนี้แล้ว
            {/* =============================================================== */}
            <br />
            <div className="h-12"></div>
            <ul className="list-disc">
              <li>
                การสั่งซื้อสินค้า หรือ บริการ
                <ul className="list-square">
                  <div className="h-6"></div>
                  <li>
                    บริษัทและเว็บไซต์นี้ เป็นเพียงตัวกลางในการจำหน่ายสินค้า หรือ
                    บริการ ซึ่งรวมถึงการจองหรือการนัดหมายต่าง ๆ จาก ผู้จัดงาน
                    ผู้ขายสินค้าหรือบริการ หรือผู้ดำเนินการใด ๆ เกี่ยวกับกิจกรรม
                    บริการ หรือสินค้า (“ผู้จัดจำหน่าย”)
                    และรับชำระค่าสินค้าหรือบริการแทนผู้จัดจำหน่ายเท่านั้น
                    ดังนั้น การสั่งซื้อสินค้าหรือบริการ ของท่านผ่านเว็บไซต์
                    จึงถือเป็นการซื้อสินค้าหรือบริการจากผู้จัดจำหน่าย
                  </li>
                  <li>
                    การสั่งซื้อสินค้าหรือบริการ
                    ที่ได้รับการยืนยันผ่านเว็บไซต์ว่าเป็นธุรกรรมที่ดำเนินการครบถ้วนสมบูรณ์แล้ว
                    ซึ่งหมายรวมถึงการที่ท่านได้ชำระค่าสินค้าหรือบริการ
                    รวมตลอดถึงค่าบริการที่เกี่ยวข้องแก่ทางบริษัทเรียบร้อยแล้ว
                    บริษัทขอสงวนสิทธิในการคืนเงิน หรือยกเลิกธุรกรรมดังกล่าว
                    แม้เป็นการทำธุรกรรมจากความผิดพลาดของผู้ใช้งานเว็บไซต์ก็ตาม
                    ทั้งนี้ แม้บริษัทจะมีการยืนยันการทำธุรกรรมหลายช่องทาง เช่น
                    การยืนยันผ่านเว็บไซต์ ทางโทรศัพท์ หรือทางอีเมล เป็นต้น
                    บริษัทให้ถือว่าการทำธุรกรรมจะมีผลสมบูรณ์เมื่อมีการยืนยันผ่านเว็บไซต์เท่านั้น
                  </li>
                  <li>
                    บริษัทไม่มีส่วนร่วมเกี่ยวกับการกำหนดราคาสินค้าหรือบริการในทุกกรณี
                    ผู้จัดจำหน่ายจะเป็นผู้กำหนดราคาสินค้า ส่วนลด หรือ
                    โปรโมชั่นต่าง ๆ (หากมี)
                  </li>
                  {/* Remove from omise request */}
                  {/* <li>
                    บริษัทไม่มีส่วนร่วมในการกำหนดจำนวนสินค้าหรือบริการที่จะจำหน่ายผ่านเว็บไซต์ในทุกกรณี
                    นอกจากนั้น ในบางกรณี
                    ผู้จัดจำหน่ายอาจจัดให้มีการจำหน่ายสินค้าหรือบริการผ่านหลายช่องทางซึ่งรวมถึงเว็บไซต์นี้
                    ดังนั้น
                    บริษัทจึงไม่สามารถยืนยันจำนวนสินค้าหรือบริการที่แน่นอนที่สามารถจำหน่ายผ่านเว็บไซต์ได้
                    การระบุจำนวนสินค้าหรือบริการผ่านทางเว็บไซต์
                    เป็นการระบุจำนวนตามที่ผู้จัดจำหน่ายกำหนดมาเท่านั้น
                  </li> */}
                </ul>
              </li>
              <li>
                วิธีการชำระเงิน
                <ul className="list-square">
                  <li>
                    ท่านสามารถชำระเงินค่าสินค้าตามวิธีการที่บริษัทกำหนดทางเว็บไซต์
                  </li>
                  <li>
                    การชำระเงินของท่านจะสมบูรณ์ต่อเมื่อได้รับการยืนยันจากทางเว็บไซต์ผ่านทางหน้าเว็บไซต์เท่านั้น
                  </li>
                </ul>
              </li>
              <li>
                ค่าบริการ
                <ul className="list-square">
                  <li>
                    ผู้ใช้บริการยอมรับว่าการสั่งซื้อสินค้าหรือบริการในลักษณะใด ๆ
                    ผ่านเว็บไซต์
                    บริษัทมีสิทธิคิดค่าบริการในการดำเนินการตามจำนวนที่ระบุสำหรับกิจกรรมที่เกี่ยวข้อง
                    ซึ่งค่าบริการอาจเปลี่ยนแปลงได้ตามแต่ละสินค้าหรือบริการ
                  </li>
                  <li>ค่าบริการในการดำเนินการจะไม่มีการคืนในทุกกรณี</li>
                </ul>
              </li>
              <li>
                ความรับผิดชอบในลักษณะของสินค้าหรือบริการ
                <ul className="list-disc">
                  <li>
                    บริษัทไม่มีส่วนเกี่ยวข้องกับการจัดการสินค้าหรือบริการในทุกกรณี
                    หากท่านพบว่าลักษณะของสินค้า
                    หรือบริการไม่ตรงกับวัตถุประสงค์ในการสั่งซื้อ
                    ท่านสามารถร้องเรียนกับทางบริษัทได้โดยตรงกับทีมงานบริการลูกค้าของทางบริษัทโดยระบุข้อมูลต่อไปนี้:
                    <ul className="list-square">
                      <div className="h-6"></div>
                      <li>ชื่อ - นามสกุล</li>
                      <li>เบอร์โทรติดต่อ</li>
                      <li>เลขที่อ้างอิงการสั่งซื้อ</li>
                      <li>วันที่นัดหมาย</li>
                      <li>สรุปคำร้องเรียน</li>
                    </ul>
                    <div className="h-6"></div>
                    จากนั้นส่งมายังอีเมลของบริษัทที่ customerservice@aappoint.me
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="h-12"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            นโยบายการยกเลิกและการคืนเงิน
          </div>
          <div className="h-12"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <ul className="list-disc">
              <li>
                สินค้าหรือบริการที่ชำระเงินแล้วไม่สามารถยกเลิกและขอคืนเงินได้ทุกกรณี
              </li>
              <li>
                ท่านยอมรับว่าสินค้าหรือบริการต่างๆอาจถูกยกเลิกหรือเลื่อนเวลาได้
                ซึ่งการยกเลิกหรือเลื่อนนั้นเป็นการพิจารณาจากผู้จัดจำหน่ายเท่านั้น
                บริษัทไม่มีส่วนในการพิจารณาแต่ประการใด
                หากท่านต้องการร้องเรียนถึงปัญหาที่เกิดขึ้น
                ท่านสามารถติดต่อกับทางบริษัทได้โดยตรงกับทีมงานบริการลูกค้าของทางบริษัทโดยระบุข้อมูลต่อไปนี้:
                <ul className="list-square">
                  <div className="h-6"></div>
                  <li>ชื่อ - นามสกุล</li>
                  <li>เบอร์โทรติดต่อ</li>
                  <li>เลขที่อ้างอิงการสั่งซื้อ</li>
                  <li>วันที่นัดหมาย</li>
                  <li>สรุปคำร้องเรียน</li>
                </ul>
                <div className="h-6"></div>
                จากนั้นส่งมายังอีเมลของบริษัทที่ customerservice@aappoint.me
              </li>
            </ul>
          </div>
          <div className="h-12"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อจำกัดความรับผิดชอบของบริษัท
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            <ul className="list-disc">
              <li>
                ท่านรับทราบและตกลงอย่างชัดแจ้งว่า
                บริษัทไม่มีความรับผิดชอบต่อความผิดพลาดของเนื้อหา (หมายถึง
                ข้อความ ภาพประกอบ รูปถ่าย เครื่องหมายการค้า ข้อมูล หรือ
                ส่วนประกอบอื่นใด) ใดๆ ในเว็บไซต์
                ที่อาจมีผลมาจากการไม่ใช่ข้อมูลล่าสุด หรือมีการพิมพ์ข้อมูลผิดพลาด
                อันเนื่องมาจากพนักงานของบริษัท
                หรือข้อมูลรายละเอียดที่ได้มาจากทางผู้จัดด้วย และ
                บริษัทขอสงวนสิทธิในการเปลี่ยนแปลงแก้ไขเนื้อหาต่าง ๆ
                โดยมิต้องแจ้งให้ทราบล่วงหน้า
              </li>
              <li>
                เนื้อหา ข้อมูล สารสนเทศต่างๆ บนเว็บไซต์ www.aappoint.me
                ไม่อยู่ในความรับผิดชอบต่อความเที่ยงตรง
                ความสมบูรณ์หรือประโยชน์ในการใช้งาน ของทางบริษัท
                ทั้งส่วนที่เกิดจากบุคคลที่สาม ผู้หนึ่งผู้ใดที่ใช้บริการ
                รวมทั้งความคิดเห็น
                และข้อแนะนำจากพนักงานที่อยู่ในความดูแลของทางบริษัทด้วย
              </li>
              <li>
                ท่านรับทราบและตกลงอย่างชัดแจ้งว่า
                บริษัทไม่มีความรับผิดชอบต่อการไม่สามารถเข้าใช้บริการส่วนใดส่วนหรือหรือทั้งหมดของเว็บไซต์
              </li>
              <li>
                ท่านตกลงรับผิดชอบถึงความเสี่ยงที่อาจเกิดขึ้นจากการการเลือกใช้เครื่องมือเพื่อรองรับการใช้บริการจากเว็บไซต์
                www.aappoint.me เช่น โทรศัพท์ คอมพิวเตอร์ ฮาร์ดแวร์
                และอุปกรณ์ต่างๆ รวมทั้งค่าบริการ จากการใช้บริการที่เกี่ยวข้อง
              </li>
              <li>
                สื่อและข้อมูลในเว็บไซต์อาจมีความคลาดเคลื่อนทางเทคนิคหรือสะกดคำผิด
                นอกจากนี้ การให้สื่อ ข้อมูล และบริการในไซต์เป็นลักษณะ "ตามสภาพ"
                โดยไม่มีเงื่อนไข การรับประกัน หรือข้อกำหนดลักษณะอื่นใด ดังนั้น
                บริษัทปฏิเสธการรับประกันและเงื่อนไขใดๆ
                ไม่ว่าโดยชัดแจ้งหรือโดยปริยายทั้งปวง
                ซึ่งรวมถึงแต่ไม่จำกัดเพียงการรับประกันโดยปริยายหรือสภาพความเหมาะสมในการใช้ประโยชน์เชิงพาณิชย์โดยปริยาย
                คุณภาพที่น่าพึงพอใจ
                ความเหมาะสมสำหรับวัตถุประสงค์หรือความต้องการในการใช้งานเฉพาะ
                ความถูกต้อง กรรมสิทธิ์
                และการไม่ละเมิดลิขสิทธิ์อันเกี่ยวข้องกับเว็บไซต์และข้อมูล
                เนื้อหา และสื่อที่อยู่ในเว็บไซต์
                หรือการใช้เว็บไซต์จะไม่เกิดการหยุดชะงักหรือการใช้เว็บไซต์จะเป็นไปตามกฎหมายที่ใช้บังคับกับท่าน
                หรือการส่งข้อมูลเกี่ยวกับท่านอันเกี่ยวข้องกับเว็บไซต์จะสำเร็จ
                ถูกต้อง หรือส่งอย่างปลอดภัย ทั้งนี้
                ข้อปฏิเสธดังกล่าวข้างต้นจะเป็นไปตามเท่าที่กฎหมายอนุญาตให้สามารถกระทำได้
              </li>
              <li>
                เท่าที่กฎหมายที่เกี่ยวข้องจะอนุญาตไว้ บริษัท และเจ้าหน้าที่
                กรรมการ พนักงาน ผู้ถือหุ้น หรือตัวแทนของบริษัท
                จะไม่มีความรับผิดสำหรับค่าเสียหายทางตรง ทางอ้อม
                ค่าเสียหายที่เป็นการลงโทษ หรือที่เป็นผลสืบเนื่อง
                หรือค่าเสียหายอื่นใดในประเภทใดก็ตาม
                ซึ่งรวมถึงแต่ไม่จำกัดเพียงการสูญเสียรายได้ กำไร ค่าความนิยม
                ข้อมูล สัญญา การใช้เงิน
                หรือการสูญเสียหรือเสียหายอันเนื่องมาจากหรือที่เกี่ยวข้องในทางใดก็ตามกับการหยุดชะงักของธุรกิจ
                ไม่ว่าในการละเมิด (ซึ่งรวมถึงแต่ไม่จำกัดเพียงความประมาทเลินเล่อ)
                สัญญา หรือโดยประการอื่น อันเป็นผลมาจาก
                หรือที่เกี่ยวข้องกับการใช้หรือการไม่สามารถใช้เว็บไซต์ เนื้อหา
                หรือสื่อที่อยู่ในเว็บไซต์หรือเข้าถึงผ่านเว็บไซต์
                รวมถึงแต่ไม่จำกัดเพียงค่าเสียหายใดๆ
                ที่เป็นผลมาจากหรือเกี่ยวข้องกับการที่ผู้ใช้เชื่อถือข้อมูลใดที่ได้รับจากเว็บไซต์
                หรือจากทางผู้จัด หรือที่เป็นผลมาจากความผิดพลาด การละเว้น
                การหยุดชะงัก การลบไฟล์หรืออีเมล ข้อผิดพลาด ความบกพร่อง ไวรัส
                ความล่าช้าในการดำเนินการหรือการส่ง หรือการไม่สามารถปฏิบัติสิ่งใด
                ไม่ว่าจะเป็นผลมาจากปรากฏการณ์ธรรมชาติ ความล้มเหลวในการสื่อสาร
                การโจรกรรม การทำลาย หรือการเข้าถึงโดยไม่ได้รับอนุญาต ซึ่งบันทึก
                โปรแกรม หรือบริการของบริษัท
              </li>
            </ul>
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อตกลงรับผิดชอบชดใช้
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            ท่านตกลงคุ้มครอง ชดใช้ และปกป้องบริษัท และผู้บริหาร กรรมการ พนักงาน
            และตัวแทนของบุคคลดังกล่าว จากการเรียกร้อง ค่าเสียหาย ค่าบริการ
            หนี้สินความรับผิดและค่าใช้จ่ายใดๆ
            (รวมถึงแต่ไม่จำกัดเพียงค่าทนายความตามสมควร)
            อันเป็นผลมาจากหรือเกี่ยวข้องกับ
            <br />
            <br />
            (1) การใช้เว็บไซต์ของท่าน
            <br />
            (2) การละเมิดข้อกำหนดและเงื่อนไขการใช้งานนี้
            <br />
            (3) การละเมิดสิทธิของบุคคลอื่นๆ หรือ
            <br />
            (4) การกระทำใดๆของคุณที่เกี่ยวข้องกับเว็บไซต์
          </div>
          {/* =============================================================== */}
          <div className="h-12"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            ข้อกำหนดเกี่ยวกับสิทธิในทรัพย์สินทางปัญญา
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            <ul className="list-disc">
              <li>
                เนื้อหาทั้งหมดที่แสดงในเว็บไซต์อยู่ภายใต้กฎหมายเกี่ยวกับทรัพย์สินทางปัญญาทั้งหมด
                โดยมีบริษัท หรือ บุคคลที่สามซึ่งอนุญาตให้บริษัทใช้
                เป็นเจ้าของทรัพย์สินทางปัญญา
                ท่านสามารถใช้เนื้อหาเหล่านั้นประกอบการใช้งานเว็บไซต์เท่านั้น
                ห้ามมิให้มีการทำซ้ำ เนื้อหาเหล่านั้น
                เพื่อใช้งานในวัตถุประสงค์อื่นๆ
              </li>
              <li>
                บริษัทสงวนสิทธิในทรัพย์สินทางปัญญาทั้งหมดโดยชัดแจ้งในต้นฉบับทั้งหมดของ
                www.aappoint.me ในด้านเนื้อหา สัญลักษณ์ ตัวอักษร กราฟฟิก
                การออกแบบ และข้อมูลทั้งหมด โดยท่านตกลงไม่ทำซ้ำ ดาวน์โหลด
                จัดจำหน่าย เผยแพร่ ส่วนหนึ่งส่วนใดของเว็บไซต์
                นอกเหนือจากการใช้งานส่วนบุคคล ห้ามลอกเลียน
                ส่วนหนึ่งส่วนใดของเว็บไซต์นี้ไม่ว่ารูปแบบใดๆ
                นอกจากจะได้รับอนุญาตเป็นลายลักษณ์อักษรจากทางบริษัท
                ทางบริษัทจะไม่รับผิดชอบเกี่ยวกับความผิดพลาดด้านเนื้อหาที่เกิดขึ้น
                จากการละเมิดลิขสิทธิ์
                หรือความเสียหายอันเกิดจากการใช้งานขอผู้ใช้บริการ ในกรณีใดๆ
                ทั้งสิ้น
              </li>
              <li>
                ห้ามมิให้ผู้ใช้บริการอัพโหลด เพื่อทำการเปลี่ยนแปลงแก้ไขข้อมูล
                หรือส่วนต่างๆบนเว็บไซต์ www.aappoint.me
                โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากทางบริษัท
                ผู้ฝ่าฝืนต้องรับผิดชอบต่อการกระทำดังกล่าวข้อมูล และสารสนเทศต่างๆ
                ที่เผยแพร่ผ่านทางเว็บไซต์ www.aappoint.me
                ถือเป็นกรรมสิทธิ์ของบริษัท ตลอดไป
                โดยไม่สามารถเรียกร้องเอาคืนด้วยเหตุผล, ข้อยกเว้น
                หรือยกข้อกฎหมายใดมากล่าวอ้างได้ โดยบริษัทฯ
                สามารถทำการใดๆต่อข้อมูล และสารสนเทศดังกล่าวได้
                ผู้ใช้บริการยอมรับให้ทางบริษัทฯ มีสิทธิ์ในการแก้ไข, ปรับปรุง,
                ทำซ้ำ, เผยแพร่, จำหน่าย ข้อมูล และสารสนเทศต่างๆ
                ที่อยู่บนเว็บไซต์ www.aappoint.me ที่เป็นของผู้ใช้บริการได้
              </li>
            </ul>
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            การดำเนินการเชื่อมโยงเว็บไซต์
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            <ul className="list-disc">
              <li>
                ท่านรับทราบและตกลงอย่างชัดแจ้งว่า
                ในกรณีที่มีการดำเนินการเชื่อมโยงจากเว็บไซต์อื่นใดมายังเว็บไซต์
                www.aappoint.me เพื่อประโยชน์ทางการค้าหรือไม่
                บริษัทไม่มีส่วนเกี่ยวข้องกับการเชื่อมโยงเว็บไซต์ดังกล่าว
                และจะไม่รับผิดชอบต่อความผิดพลาดใดๆ หรือความเสียหาย
                ซึ่งอาจเกิดขึ้นจากการเข้าใช้งานเว็บไซต์ผ่านทางการเชื่อมโยงผ่านจากเว็บไซต์อื่น
                ไม่ว่าในกรณีใดๆ
              </li>
              <li>
                เว็บไซต์ www.aappoint.me
                มีจุดเชื่อมโยงข้อมูลไปยังเว็บไซต์อื่นที่ไม่ได้อยู่ในความดูแลของบริษัท
                ดังนั้นบริษัทขอปฏิเสธความผิดชอบต่อผลอันใดที่เกิดขึ้นจากข้อมูลหรือกิจกรรมในเว็บไซต์เหล่านั้นทั้งทางตรงและทางอ้อม
              </li>
            </ul>
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            การระงับ แก้ไข ปรับเปลี่ยน เว็บไซต์
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            บริษัทขอสงวนสิทธิในการแก้ไขปรับเปลี่ยนหรือหยุดให้บริการโดยชั่วคราวหรือถาวรซึ่งเว็บไซต์หรือส่วนหนึ่งส่วนใดของเว็บไซต์โดยไม่มีการบอกกล่าวล่วงหน้า
            คุณตกลงและยอมรับว่าบริษัทจะไม่มีความรับผิดจากการแก้ไขปรับเปลี่ยน
            ระงับ หรือหยุดให้บริการไซต์หรือส่วนหนึ่งส่วนใดของเว็บไซต์
          </div>
          {/* =============================================================== */}
          <div className="h-6"></div>
          <div
            className="
                        text-body-header font-bold
                        text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            การแยกต่างหากจากกันของข้อสัญญา
          </div>
          <div className="h-4"></div>
          <div
            className="text-body-content-gray
                        text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                        leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                    "
          >
            <br />
            หากข้อสัญญาใดในข้อตกลงนี้ถือว่าไม่ชอบด้วยกฎหมาย เป็นโมฆะ
            หรือไม่สามารถบังคับได้ด้วยเหตุใดก็ตาม
            ข้อสัญญานั้นจะถือว่าแยกออกมาจากข้อตกลงฉบับนี้
            และไม่มีผลกระทบต่อความสมบูรณ์และความมีผลใช้บังคับของข้อสัญญาอื่นที่เหลือ
          </div>
        </div>
        <div className="h-32"></div>
        <div>
          <div className="container-mobile mx-auto">
            <div
              className="
                            text-body-header font-bold
                            text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                        "
            >
              ติดต่อเรา
            </div>
            <div className="h-4"></div>
            <div
              className="text-body-content-gray
                            text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
                            leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
                        "
            >
              <br />
              หากต้องการข้อมูลเพิ่มเติม คำถาม หรือคำแนะนำ ติชม
              ในข้อตกลงการใช้บริการ โปรดติดต่อเรา
              <br />
              <br />
              บริษัท แอพพ้อยท์เม้นท์ เอนี่แวร์ จำกัด (สำนักงานใหญ่)
              <br />
              239/2 อาคารลุมพินี 1 ห้อง 52 ชั้น 5 ถนนราชดำริห์
              <br />
              แขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330
              <br />
              <br />
              ได้ในเวลาทำการของผู้ให้บริการตั้งแต่เวลา 09.00 - 17.00
              <br />
              หรือทางหมายเลขโทรศัพท์ 02-057-9135 หรือทางอีเมลล์
              customerservice@aappoint.me
            </div>
            {/* =============================================================== */}
          </div>
        </div>
      </div>
    </div>
  )
}
